import React from "react";
import './storyCard.css';

const StoryCard = (props) => {
  const { date, hero, mood, story } = props;
  const itemColor = mood === ':)' ? 'green' : mood === ':(' ? 'red' : 'blue';

  return (
    <div className={'item ' + itemColor}>
      <div className="title">
        <div className="piece">{date}</div>
        <div className="piece">{hero}</div>
        <div className="piece">{mood}</div>
      </div>
      <div className="story">{story}</div>
    </div>
  )
}

export default StoryCard;