const funny = [
  {
    date: '2020-10-28', hero: 'А', mood: ':)',
    story: 
    `Снежка диктува текст за Васил Друмев на Андрей:
    - Васил Друмев е роден през 1838г. в град Шумен. Още много млад... 
    Чувайки това, Андрей възкликва:
    - Още много млад ли? 200 години минаха.`
  },
  {
    date: '2021-02-07', hero: 'М', mood: ':)',
    story: 
    `Сутринта Марти се събужда от сън, видимо щастлив.
    - Мамо, сънувах страхотен сън.
    - Какво сънува?
    - Че намирам 2 лева.
    (По-късно същия ден намери 10 лева.)`
  },
  {
    date: '2021-02-11', hero: 'М', mood: ':(',
    story:
    `С Марти отиваме да вземем Андрей от училище. Паркирам и питам Марти, който е сърдит за нещо, дали ще слезе. Той ме пита ядосано:
    - Кое ще ти е по-гадно, да сляза или не?
    - Няма да ти отговоря на този въпрос - казах аз и слязох от колата, оставяйки го да чака там.`  
  },
  {
    date: '2021-02-19', hero: 'М', mood: ':)',
    story:
    `Снежка води Марти на градина с колата и казва:
    - Как по дяволите винаги пред мен се пада някакъв който кара ултра бавно?!?
    А Марти отговаря:
    - А пред тати винаги се пада някой глупак.`
  },
  {
    date: '2021-02-25', hero: 'М', mood: ':)',
    story:
    `Марти хрупа дръжки от магданоз докато вечеряме. Снежка го пита:
    - Ако ти дам сега сладолед, ще го предпочетеш ли пред магданоза?
    - Колко сладолед? - попита Марти.
    - Една купа сладолед и една купа магданоз.
    - Ще избера магданоза. - заяви убедително Марти и продължи да хрупа дръжките.`
  },
  {
    date: '2021-02-28', hero: 'А', mood: ':)',
    story:
    `Снежка реже ноктите на Анди:
    - Мамо, защо не изрежеш твоите нокти? Много по-дълги са.
    - Аз съм жена. Моите са красиви и не са черни.
    - Да, ти ги лакираш, за да не се вижда, че са черни.`
  },
  {
    date: '2021-02-28', hero: 'М', mood: ':)',
    story:
    `Марти споделя какво би направил ако може да промени нещо в света:
    - Искам да се изаквам за една секунда и дупето ми да се изчиства само.`
  },
  {
    date: '2021-03-04', hero: 'М', mood: ':о',
    story:
    `Марти си гризе ноктите на ръцете от известно време. Ние няколко пъти му казахме, че ако продължава, ще му спрем сладкото, щом ноктите са му толкова вкусни.
    Тази вечер Снежка му поглежда краката и вижда, че ноктите му са дълбоко изрязани, но нито аз нито тя ги е рязала.
    Въпреки, че и на двамата ни се струва странно, все пак питаме Марти да не би да ги е гризал, а той отговаря:
    - Нищо не сте казали за краката.`
  },
  {
    date: '2021-04-02', hero: 'М', mood: ':)',
    story:
    `Марти идва при мен с брошура от Кауфланд и ми казва:
    - Тате, добри новини. Бирата е на промоция.`
  },
  {
    date: '2021-04-13', hero: 'М', mood: ':)',
    story:
    `Гледаме предаването "Един за друг". Двойките имат за задача да бъркат в аквариум със змии.
    Започваме да го обсъждаме и казвам, че бих целунал паяк.
    - Ще целунеш паяк пред жена си? - пита ме Мартин.
    - Да, това е приятелска целувка. - отговарям аз.
    - Няма приятелски целувки. - отвръща ми категорично той.`
  },
  {
    date: '2021-04-30', hero: 'А', mood: ':)',
    story:
    `Пускаме си "Лигата на справедливостта - Режисьорска версия" по HBO GO. Понеже е с надписи, аз чета на децата и стигам до репликата "Извикайте кралицата".
    - Коя е кралицата? - пита Андрей.
    - Майката на Жената Чудо. - отговарям аз.
    - Значи Бабата Чудо. - заключва той.`  
  },
  {
    date: '2021-07-25', hero: 'М', mood: ':)',
    story:
    `Марти казва на Анди:
    - Не е лесно да си в моя живот.
    - Защо? - пита го Анди?
    - Много ме харесват.`
  },
  {
    date: '2021-08-06', hero: 'М', mood: ':)',
    story:
    `Вечеряме и Марти подхвърля:
    - Андрей е луд за кюфтета.
    - Защото втората ми дума е била "кюфте" - отговаря АНдрей.
    - Бедничкия тати. - казва тъжно Марти.`
  },
  {
    date: '2021-08-13', hero: 'A', mood: ':)',
    story:
    `Къпя се и Андрей влиза в банята:
    - Какво ще правиш? - питам го аз.
    - Ще акам. - отговаря ми той.
    - Не можеш ли да изчакаш малко? Не искам да ми мирише.
    - Това няма да те убие.`
  },
  {
    date: '2021-09-07', hero: 'М', mood: ':)',
    story:
    `Около 22:30 Снежка казва на Марти:
    - Време е да лягаш.
    - Ама тате ще ми дава компютъра. - казва той.
    - В какъв смисъл? - пита го Снежка.
    - В буквален. - отговаря Марти.`
  },
  {
    date: '2021-09-12', hero: 'М', mood: ':)',
    story:
    `Марти играе Mine Craft и убива няколко овце и прасета.
    Снежка го вижда и пита:
    - Защо ги убиваш?
    - Или аз или те.`
  },
  {
    date: '2021-09-29', hero: 'М', mood: ':)',
    story:
    `Връщаме се с децата от училище. Марти и Анди си играят на задната седалка на колата.
    Марти е хванал двата края на устата си с пръсти, дърпа ги надолу и казва:
    - Толкова съм ядосан, че устата ми не може да го покаже.`
  },
  {
    date: '2021-10-23', hero: 'М', mood: ':)',
    story:
    `Връщаме се с децата вкъщи, а на Марти му се пикае. Аз не мога да си намеря ключа, а Марти подскачайки от крак на крак, казва:
    - Добре че имам голям мехур.`
  },
  {
    date: '2021-10-24', hero: 'М', mood: ':)',
    story:
    `След вечеря Марти иска нещо сладко. Предлагам му дюля (той много обича дюли).
    - Искам дюля, но това няма да се брои за сладко.
    - А за какво ще се брои? За кисело ли? - питам го аз.
    - За стипцаво.`
  },
  {
    date: '2021-11-07', hero: 'М', mood: ':)',
    story:
    `По някаква причина споменавам, че Снежка е на 40 години. Марти веднага ми казва:
    - Тя не обича да се споменава това.
    - Защо? - питам го аз.
    - Защото мисли, че е стара. - прошепва Марти.`
  },
  {
    date: '2021-11-11', hero: 'А', mood: ':)',
    story:
    `От известно време Анди си търка краищата на устата с пръсти, след което ги души. Опитваме да го откажем, но е трудно.
    Виждам, че пак го прави и му казвам:
    - Спри да го правиш! Дръж се като мъж!
    Той ме поглежда, след това поглежда ръката си и казва:
    - Не мога. Много съм слаб.`
  },
  {
    date: '2021-11-15', hero: 'М', mood: ':)',
    story:
    `Андрей в трети клас има предмет "Компютърно моделиране". Марти разсъждава по темата:
    - По "Компютърно моделиране" играят на компютър. Щеше да е по-яко да имат телефонно моделиране и да играят на телефоните.`
  },
  {
    date: '2021-11-17', hero: 'М', mood: ':(',
    story:
    `С Андрей седим пред компютъра и се учим как се нарежда кубчето на Рубик.
    Мартин се качи до мен на дивана и ми пръдна в лицето.`
  },
  {
    date: '2021-11-20', hero: 'М', mood: ':)',
    story:
    `Марти грае на Brawl Stars на телефона си. Пада му се нов герой и в изблик на радост той извиква:
    - Шахтаг нов герой. Шахтаг нов зъб.
    На предния ден му беше паднал зъб.`
  },
  {
    date: '2021-11-22', hero: 'М', mood: ':)',
    story:
    `Преди вечеря Снежка казва на Анди, че днес му е последното наказание като 8 годишен.
    - А когато сме на 19 години, колко наказания ще имаме? - пита Марти, след което самодоволно отговаря:
    - Нула!`
  },
  {
    date: '2021-11-22', hero: 'М', mood: ':)',
    story:
    `Около 23:00 децата са легнали на дивана в хола и отказват да отидат да спят в детската. Снежка им казва:
    - Ако убедите баща си, може да останете да спите на дивана.
    - Значи 100% ще си спим в стаята. - казва Марти.
    - Защо, аз по-глупава ли съм?
    - Не. Ти си по-лесничка. - отговаря Марти.`
  },
  {
    date: '2021-11-29', hero: 'М', mood: ':)',
    story:
    `Отивам да пия вода, да си направя крачките и да съм здравичък.`  
  },
  {
    date: '2021-12-03', hero: 'М', mood: ':)',
    story:
  `Гледаме Игри на волята и с Боби коментираме, че са пуснали реклама за кастинг за следващия сезон. Марти пита Симеон (един от участниците в предаването, който влезе в предаването малко по-късно като в началото беше сам на самотен плаж) от колко време е на този самотен плаж. Ние му казваме че е там откакто е започнало предаването и Марти ужасно учуден казва:
    - Че той участник ли е, мислех че са го намерили на плажа.`
  },
  {
    date: '2021-12-04', hero: 'М', mood: ':)',
    story:
  `Говорим си кой какво работи, какво работи Боби, какво работя аз. Разказвам на Марти и Анди, че работя в екип, които се грижи за обработката на транзакции с карти на терминали и им обяснявам, че работата ми е много интересна и Марти казва:
    - Какво каза, че заспах`
  },
  {
    date: '2021-12-05', hero: 'М', mood: ':)',
    story:
    `Играя "God of War" на PS3, а децата ме гледат. Опитвам се да направя нещо, но се провалям няколко пъти по ред.
    - Само да си посмял! Ще съм ти в сънищата.
    - В кошмарите или в сладките?
    - И в двете.`
  },
  {
    date: '2021-12-11', hero: 'М', mood: ':)',
    story:
  `Предлагам на Марти шоколадови бисквити. Той ги поглежда и казва:
    - Тези не ги обичам.
    - Много са хубави - му казвам аз.
    - Хубави за коша за боклук - отговаря ми той.`
  },
  {
    date: '2021-12-24', hero: 'А', mood: ':)',
    story:
  `Анди гледа пъпките на гърба ми и казва:
    - Тати има най-много пъпки на света.
    - Надявам се да не е вярно - казвам аз.
    - В топ 5 си - отговаря ми той.`
  },
  {
    date: '2021-12-31', hero: 'М', mood: ':)',
    story:
  `Гледали сме Хари Потър до 2 сутринта. Казвам им да си лягат и добавям:
    - Трябва да ми целувате ръката, за това че ви оставих да гледате до толкова късно.
    - Чак да ти целуваме ръка. Малко е преувеличено. - отговаря ми Марти.`
  },
  {
    date: '2022-01-06', hero: 'А', mood: ':)',
    story:
  `Питам децата дали искат да си сготвим боб утре (петък).
   - Супер! Ще си пръцкаме цял уикенд. - казва Анди.`
  },
  {
    date: '2022-01-21', hero: 'М', mood: ':)',
    story:
  `Преди няколко дни обяснявах на децата, че не трябва да се разстройват когато нещо се случи не както са очаквали. В тази връзка им казах поговорката "Когато животът ти даде лимони, направи си лимонада!".
  Днес марти яде кисело мляко и казва:
   - Когато животът ти даде кисело мляко - ядеш.`
  },
  {
    date: '2022-01-27', hero: 'М', mood: ':)',
    story:
  `За вечеря сме поръчали пица. Марти си взима трето парче, а Анди четвърто.
   - Май като порастнеш ще станеш като тати. - казва Марти.
   - Аз не съм дебел. - включвам се аз.
   - В момента говорим помежду си. Не се замесвай. - отряза ме Марти.`
  },
  {
    date: '2022-02-25', hero: 'М', mood: ':)',
    story:
  `Андрей и Мартин си говорят в колата, как да впечатлят момиче.
  Мартин е много напред с материала.`
  },
  {
    date: '2022-02-18', hero: 'М', mood: ':)',
    story:
  `Марти вижда пържени картофи по телевизията и казва, че много обича. Аз му предлагам да му изпържа в свинска мас.
   - Стават много мекички. - казвам аз.
   - Не ми пука, че нямам зъби. Аз, обичам, твърди. - отговаря Марти натъртвайки всяка дума.`
  },
  {
    date: '2022-03-02', hero: 'А', mood: ':)',
    story:
  `С Андрей правим домашно по "Човек и общество". Има картинки с предмети, от чиито имена трябва да извадят по няколко букви от началото или от края.
  Като краен резултат се получава "Черноризец Храбър - За буквите".
  След като от "тиква" сме получили "кв" и сме продължили до края на картинките, Андрей е забравил това и се опитвам да го подсетя:
   - Какво се вади от "тиква"? - питам го аз няколко пъти.
   - Ааа, семки. - отговаря убедително той.`
  },
  {
    date: '2022-03-17', hero: 'М', mood: ':)',
    story:
  `Приготвяме се за вечеря. Аз нося голям нож в хола, а децата се правят на уплашени.
   - Страшен нож! Той ще ни убие. - извиква Андрей
   - Не мислех, че животът ми ще свърши така. - възкликва Мартин.`
  },
  {
    date: '2022-03-26', hero: 'М', mood: ':)',
    story:
  `Часът е 23:20. Пускам филм, а Марти ме пита:
   - Страшен ли е?
   - Да. - отговарям аз.
   - Окей - казва Марти, изгася лампата и се настанява до мен да гледа.`
  },
  {
    date: '2022-04-08', hero: 'М', mood: ':)',
    story:
  `Часът е 00:40 и се опитвам да сложа Марти да спи, а той заявява:
   - Нали знаеш, че не съм човек на лягането. Както казват в поговорката "вечер ме бият да лягам, сутрин ме ритат да ставам".`
  },
  {
    date: '2022-07-22', hero: 'А', mood: ':)',
    story:
  `Пътуваме в колата. Децата са на задната седалка и Андрей казва уверено:
   - Аз съм Гей.
   - Аз съм Гейл. - възразява Мартин.
  Аз си отдъхнах, че не съм чул добре казаното от Андрей.`
  },
  {
    date: '2022-09-08', hero: 'М', mood: ':)',
    story:
  `Събуждам Марти от сън в 11:15. Той стиска очи и се натиска в леглото, а аз му повтарям:
   - Ставай! Ставай!...
  След малко той промърморва:
   - Няма ли някой друг на когото да досаждаш?`
  },
  {
    date: '2022-09-10', hero: 'М', mood: ':)',
    story:
  `С Марти играем на нещо като морски шах, но трябва да направиш линия от четири еднакви по цвят топчета.
  Той ме блокира и е видимо доволен, заради което аз му казах:
   - Много си гаден.
   - Благодаря. - Отвърна ми той.
   - Това не беше комплимент.
   - За мен е.`
  },
  {
    date: '2022-09-10', hero: 'А М', mood: ':)',
    story:
  `Гледаме финала на US Open между Швьонтек (Полша) и Жабьор (Тунис).
  Мартин вижда тунизийката и казва:
   - Аз съм за другата ако е по-хубава.
   - Да. - Отговаря Андрей. - По-хубава е.`
  },
  {
    date: '2022-09-15', hero: 'А', mood: ':)',
    story:
   `- С дънките не мога да се душа. Знаеш ли защо?
   - Защото не можеш да си бъркаш в задника?
   - Да. Мръсни дънки!`
  },
  {
    date: '2022-09-22', hero: 'М', mood: ':)',
    story:
  `С Марти разглеждаме сайт за слушалки. Той си хареса един модел, стана от компютъра и каза:
   - Може ли да проведа един разговор със себе си?
  След това излезе от стаята ухилен.`
  },
  {
    date: '2022-11-27', hero: 'А', mood: ':|',
    story:
  `Андрей ходи на частни уроци по Математика от около 2 месеца. Тази вечер разговаряме и му обяснявам, че ако има нещо, което не му е ясно, трябва да поиска от учителката да му го обясни. Ако трябва тя ще му обяснява една и съща задача по 5 пъти докато му се изясни, защото работата ѝ е да го научи.
   - Не мисля, че тя е на същото мнение. - Отговаря ми той.`
  },
  {
    date: '2022-12-09', hero: 'М', mood: ':)',
    story:
  `Седим всички в хола. Марти се приближава до мен и прошепва:
   - Наистина ли ще купите на Андрей пропуск за играта?
  Това е обещано от Снежка за това, че Андрей ще участва на олимпиада по математика. За всяка изкарана точка има допълнителен бонус.
   - Да. - Отговарям аз.
   - Май сте много отчаяни. - Казва тихо Марти.`
  },
  {
    date: '2022-12-12', hero: 'М', mood: ':)',
    story:
  `Приключих с вечерята и казвам:
   - Много добре хапнах.
   - Е да. Ти винаги хапваш много добре. - Отговаря Мартин.`
  },
  {
    date: '2022-12-24', hero: 'А М', mood: ':)',
    story:
  `Децата стоят на дивана. Анди играе на таблета, а Марти го гледа.
   - Момчета, дядо Павел какъв подарък би искал за Коледа? - Питам ги аз.
   - Ракия. - Отговаря бързо Андрей.
   - Ние все това му подаряваме. - Казвам аз.
   - Е да, той я харесва. - Включва се Мартин.
  Аз се засмивам, а той продължава:
   - Той много обича ракия.`
  },
  {
    date: '2023-01-14', hero: 'М', mood: ':)',
    story:
    `Събуждам Мартин към 12:30 и му казвам какво ще правим през деня:
   - Ще закусим...
   - Не съм гладен - отвръща бързо той.
   - Може да минем през МакДоналдс... - продължавам аз.
   - Вече съм гладен - усмихва се той.`
  }
];

export {funny};