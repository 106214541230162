import logo from './logo.svg';
import './App.css';
import StoryCard from './components/storyCard'
import {funny} from './data/stories'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {!!funny && funny.map(i => (
          <StoryCard date={i.date} hero={i.hero} mood={i.mood} story={i.story} />
          ))}
      </header>
    </div>
  );
}

export default App;
